import { Observable, observable } from '@legendapp/state'

import GlobalState from 'trellis:state/globalState'

export interface IClaimsPermissions {
  HideGridTabs: boolean
}

export interface IRoutePermissions {
  hasEligibility?: boolean
  hasClaims?: boolean
  hasCarrierList?: boolean
  hasStandaloneAttachments?: boolean
  hasMemberPlans?: boolean
  hasPayments?: boolean
  hasBillingStatements?: boolean
}

export enum AccountType {
  Unknown, //default so 0 doesn't match anything
  Trellis,
  DentalIntel,
  RemoteLite,
  BillingAndPayments,
}

export interface IRoleHelper {
  defaultRoute: string
  routePermissions: IRoutePermissions
  accountType: number
  isTrellis: boolean
  isDentalIntel: boolean
  Claims: IClaimsPermissions
}

export const RoleHelper$: Observable<IRoleHelper> = observable<IRoleHelper>({
  defaultRoute: () => {
    if (!GlobalState.IsAuthenticated.get()) {
      return '/'
    } else if (
      RoleHelper$.accountType.get() == AccountType.BillingAndPayments.valueOf()
    ) {
      return '/Statements'
    } else if (RoleHelper$.routePermissions.hasClaims.get()) {
      return '/Claims'
    } else {
      //this will probably only happen if they have an invalid active service setup
      return '/Practice/PracticeInfo'
    }
  },
  routePermissions: {
    //insurance
    hasClaims: () =>
      GlobalState.ActiveServices.TRELLIS_CLAIMS.get() ||
      GlobalState.ActiveServices.REMOTE_LITE.get() ||
      GlobalState.DecodedJwt.customer_type.get() === '2',
    hasCarrierList: () => RoleHelper$.routePermissions.hasClaims.get(),
    hasStandaloneAttachments: () =>
      [AccountType.Trellis].includes(RoleHelper$.accountType.get()),
    hasMemberPlans: () => RoleHelper$.routePermissions.hasClaims.get(),
    hasEligibility: () => RoleHelper$.routePermissions.hasClaims.get(),
    //statements+payments
    hasPayments: () =>
      [
        AccountType.Trellis,
        AccountType.BillingAndPayments,
        AccountType.RemoteLite,
      ].includes(RoleHelper$.accountType.get()),
    hasBillingStatements: () => {
      return (
        RoleHelper$.accountType.get() ===
          AccountType.BillingAndPayments.valueOf() ||
        (RoleHelper$.accountType.get() == AccountType.Trellis.valueOf() &&
          GlobalState.PracticeInfo.p_Software
            .get()
            ?.toUpperCase()
            .includes('EAGLE')) //git it a bit of leeway in case someone hand typed it
      )
    },
  },
  accountType: () => {
    if (GlobalState.ActiveServices.DENTAL_INTEL.get()) {
      return AccountType.DentalIntel
    } else if (GlobalState.ActiveServices.REMOTE_LITE.get()) {
      return AccountType.RemoteLite
    } else if (GlobalState.ActiveServices.TRELLIS_CLAIMS.get()) {
      return AccountType.Trellis
    } else if (
      GlobalState.ActiveServices.TRELLIS_PAYMENTS.get() ||
      GlobalState.ActiveServices.TRELLIS_STATEMENTS.get()
    ) {
      return AccountType.BillingAndPayments
    }
    return AccountType.Unknown
  },
  isTrellis: () => GlobalState.ActiveServices.TRELLIS_CLAIMS.get(),
  isDentalIntel: () => GlobalState.ActiveServices.DENTAL_INTEL.get(),
  Claims: {
    HideGridTabs: () => (RoleHelper$.isTrellis.get() ? false : true),
  },
})

export const hasRoutePermission = (path: string): boolean => {
  switch (path?.toLowerCase()) {
    case '/statements':
      return RoleHelper$.routePermissions.hasBillingStatements.get()
    case '/claims':
      return RoleHelper$.routePermissions.hasClaims.get()
    case '/carrierlist':
      return RoleHelper$.routePermissions.hasCarrierList.get()
    case '/attachments':
      return RoleHelper$.routePermissions.hasStandaloneAttachments.get()
    case '/memberplans':
      return RoleHelper$.routePermissions.hasMemberPlans.get()
    case '/payment/paymentsportal':
      return RoleHelper$.routePermissions.hasPayments.get()
    case '/eligibility':
      return RoleHelper$.routePermissions.hasEligibility.get()
  }

  return true
}
