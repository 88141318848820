import { Avatar, Form, Tabs } from 'antd'
import { FC, useEffect } from 'react'

import { Loading } from '../_siteWide/loader/dataDisplay'
import {
  BillingContent,
  NoBillingContent,
} from '../_siteWide/payment/billing/billingContent'
import {
  PaymentContextProvider,
  usePaymentContext,
} from '../_siteWide/payment/context/paymentContext'

import '../_siteWide/payment/payment.scss'

import { observer } from '@legendapp/state/react'

import { LogError } from 'utils'

import { GetBillingDetails } from 'trellis:api/practice/practiceApi'
import GlobalState from 'trellis:state/globalState'
import { showMessage } from 'trellis:utilities/general'

import { InvoiceContextProvider } from '../_siteWide/payment/context/invoiceContext'
import { Badge } from '@vynedental/design-system'

const BillingDetails: FC = observer(() => {
  const { loading, payment, setIsNew, setLoading, setPayment } =
    usePaymentContext()

  const paymentType = GlobalState.BillingDetails.paymentType.get()

  const errorCount: number = payment?.errors?.length

  const [form] = Form.useForm()

  useEffect(() => {
    setLoading(true)

    GetBillingDetails()
      .then(({ data }) => {
        setIsNew(false)
        setLoading(false)
        setPayment({ ...payment, ...data })
      })
      .catch((e) => {
        LogError(e, 'Failed to get billing details')
        showMessage(
          'There was an error loading Billing Details. Please try again later.',
        )
        setLoading(false)
      })
  }, [])

  form.setFieldsValue({ ...payment })

  return (
    <article className='page-content__container page-content__container--with-page-header'>
      <h1 className='page-content-header__title'>My Practice</h1>
      <Tabs
        className='pt-100'
        items={[
          {
            children: (
              <>
                {loading && <Loading />}
                {!loading && (
                  <Form form={form}>
                    {paymentType.toLocaleUpperCase() === 'CREDIT CARD' && (
                      <BillingContent />
                    )}
                    {paymentType.toLocaleUpperCase() !== 'CREDIT CARD' && (
                      <NoBillingContent />
                    )}
                  </Form>
                )}
              </>
            ),
            key: 'Payment Information',
            label: 'Billing Details',
            icon: errorCount ? <Badge count={errorCount} name='billing-details-errors' /> : null
          },
        ]}
      />
    </article>
  )
})

export default () => (
  <InvoiceContextProvider>
    <PaymentContextProvider>
      <BillingDetails />
    </PaymentContextProvider>
  </InvoiceContextProvider>
)
